import React, { useState, useCallback } from 'react';
import {
    Container,
    Typography,
    Grid,
    Box,
    CircularProgress,
    Chip,
    Autocomplete,
    TextField,
    Button,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    List,
    ListItem,
    ClickAwayListener,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material';
import { apiService } from '../../services/api.service';
import { LegoPart, LegoSet, ItemType, LegoColor, StockSoldRatio, SoldQuantityOperator } from '../../types/lego.types';
import PartDetails from './PartDetails';
import { LegoSetDetails } from './LegoSetDetails';
import { useNavigate } from 'react-router-dom';

interface SearchResult {
    id: string;
    no: string;
    name: string;
    type: ItemType;
    imageUrl: string;
    category_name?: string;
    color?: LegoColor;
    stockSoldRatio?: number;
    soldQuantity?: number;
    stockMinPrice?: string;
    soldAvgPrice?: string;
}

const ALL_TYPES: ItemType[] = [
    'SET',
    'PART',
    'MINIFIG',
    'BOOK',
    'GEAR',
    'CATALOG',
    'INSTRUCTION',
    'ORIGINAL_BOX',
    'UNSORTED_LOT'
];

const TYPE_LABELS: { [key in ItemType]: string } = {
    SET: 'Sets',
    PART: 'Parts',
    MINIFIG: 'Minifigures',
    BOOK: 'Books',
    GEAR: 'Gear',
    CATALOG: 'Catalogs',
    INSTRUCTION: 'Instructions',
    ORIGINAL_BOX: 'Original Boxes',
    UNSORTED_LOT: 'Unsorted Lots'
};

const ColorBox: React.FC<{ colorCode: string }> = ({ colorCode }) => (
    <Box
        sx={{
            width: 20,
            height: 20,
            bgcolor: `#${colorCode}`,
            border: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'inline-block',
            verticalAlign: 'middle',
            mr: 1
        }}
    />
);

const Search: React.FC = () => {
    const navigate = useNavigate();
    const [numberSearch, setNumberSearch] = useState('');
    const [selectedTypes, setSelectedTypes] = useState<ItemType[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedColors, setSelectedColors] = useState<LegoColor[]>([]);
    const [categoryInput, setCategoryInput] = useState('');
    const [colorInput, setColorInput] = useState('');
    const [categorySuggestions, setCategorySuggestions] = useState<string[]>([]);
    const [colorSuggestions, setColorSuggestions] = useState<LegoColor[]>([]);
    const [loadingCategorySuggestions, setLoadingCategorySuggestions] = useState(false);
    const [loadingColorSuggestions, setLoadingColorSuggestions] = useState(false);
    const [showCategorySuggestions, setShowCategorySuggestions] = useState(false);
    const [showColorSuggestions, setShowColorSuggestions] = useState(false);
    const [results, setResults] = useState<SearchResult[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedPart, setSelectedPart] = useState<LegoPart | null>(null);
    const [selectedSet, setSelectedSet] = useState<LegoSet | null>(null);
    const [partDetailsOpen, setPartDetailsOpen] = useState(false);
    const [setDetailsOpen, setSetDetailsOpen] = useState(false);
    const [separateColors, setSeparateColors] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [stockSoldRatio, setStockSoldRatio] = useState<StockSoldRatio>('ALL');
    const [soldQuantityOperator, setSoldQuantityOperator] = useState<SoldQuantityOperator>('GT');
    const [soldQuantityValue1, setSoldQuantityValue1] = useState<number>(0);
    const [soldQuantityValue2, setSoldQuantityValue2] = useState<number>(0);

    const RATIO_LABELS: { [key in StockSoldRatio]: string } = {
        'ALL': 'All Ratios',
        'LT_1': 'Less than 1:1',
        'LT_3': 'Less than 3:1',
        'LT_5': 'Less than 5:1',
        'LT_10': 'Less than 10:1'
    };

    const categorySearchTimerRef = React.useRef<NodeJS.Timeout>();
    const colorSearchTimerRef = React.useRef<NodeJS.Timeout>();

    const handleSearch = useCallback(async () => {
        if (selectedTypes.length === 0) {
            return;
        }

        setLoading(true);
        setHasSearched(true);
        try {
            let searchResults: SearchResult[] = [];
            let total = 0;

            const response = await apiService.searchParts({
                page,
                limit: 24,
                types: selectedTypes,
                categories: selectedCategories,
                colors: selectedColors.map(c => c.color_id),
                separateColors,
                stockSoldRatio,
                numberSearch: numberSearch.trim(),
                soldQuantityFilter: {
                    operator: soldQuantityOperator,
                    value1: soldQuantityValue1,
                    ...(soldQuantityOperator === 'BETWEEN' && { value2: soldQuantityValue2 })
                }
            });

            searchResults = response.data.data.parts.map(part => ({
                id: part._id,
                no: part.no,
                name: part.name,
                type: part.type,
                imageUrl: part.imageUrl,
                category_name: part.category_name,
                color: part.color,
                stockSoldRatio: part.stockSoldRatio,
                soldQuantity: part.soldQuantity,
                stockMinPrice: part.stockMinPrice,
                soldAvgPrice: part.soldAvgPrice
            }));
            total = response.data.data.pagination.total;

            setResults(searchResults);
            setTotalPages(Math.ceil(total / 24));
        } catch (error) {
            console.error('Error searching:', error);
        } finally {
            setLoading(false);
        }
    }, [
        selectedTypes,
        selectedCategories,
        selectedColors,
        separateColors,
        stockSoldRatio,
        soldQuantityOperator,
        soldQuantityValue1,
        soldQuantityValue2,
        numberSearch,
        page
    ]);

    const handleCategoryInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCategoryInput(value);
        setShowCategorySuggestions(true);
        
        if (categorySearchTimerRef.current) {
            clearTimeout(categorySearchTimerRef.current);
        }

        categorySearchTimerRef.current = setTimeout(async () => {
            if (value.trim()) {
                setLoadingCategorySuggestions(true);
                try {
                    const response = await apiService.getCategorySuggestions(value);
                    const newSuggestions = response.data.data.filter(
                        category => !selectedCategories.includes(category)
                    );
                    setCategorySuggestions(newSuggestions);
                } catch (error) {
                    console.error('Error fetching category suggestions:', error);
                    setCategorySuggestions([]);
                } finally {
                    setLoadingCategorySuggestions(false);
                }
            } else {
                setCategorySuggestions([]);
            }
        }, 300);
    };

    const handleColorInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setColorInput(value);
        setShowColorSuggestions(true);
        
        if (colorSearchTimerRef.current) {
            clearTimeout(colorSearchTimerRef.current);
        }

        colorSearchTimerRef.current = setTimeout(async () => {
            if (value.trim()) {
                setLoadingColorSuggestions(true);
                try {
                    const response = await apiService.getColorSuggestions(value);
                    const newSuggestions = response.data.data.filter(
                        color => !selectedColors.some(c => c.color_id === color.color_id)
                    );
                    setColorSuggestions(newSuggestions);
                } catch (error) {
                    console.error('Error fetching color suggestions:', error);
                    setColorSuggestions([]);
                } finally {
                    setLoadingColorSuggestions(false);
                }
            } else {
                setColorSuggestions([]);
            }
        }, 300);
    };

    const handleCategoryClickAway = () => {
        setShowCategorySuggestions(false);
        setCategorySuggestions([]);
        if (!categoryInput.trim()) {
            setCategoryInput('');
        }
    };

    const handleColorClickAway = () => {
        setShowColorSuggestions(false);
        setColorSuggestions([]);
        if (!colorInput.trim()) {
            setColorInput('');
        }
    };

    const handleCategorySelect = (suggestion: string) => {
        setSelectedCategories([...selectedCategories, suggestion]);
        setShowCategorySuggestions(false);
        setCategorySuggestions([]);
        setPage(1);
    };

    const handleColorSelect = (color: LegoColor) => {
        setSelectedColors([...selectedColors, color]);
        setShowColorSuggestions(false);
        setColorSuggestions([]);
        setPage(1);
    };

    const handleItemClick = async (result: SearchResult) => {
        if (result.type === 'SET') {
            try {
                const response = await apiService.getLegoSetByNumber(result.no);
                setSelectedSet(response.data.data);
                setSetDetailsOpen(true);
            } catch (error) {
                console.error('Error fetching set details:', error);
            }
        } else {
            const part = {
                _id: result.id,
                no: result.no,
                name: result.name,
                type: result.type,
                imageUrl: result.imageUrl,
                thumbnailUrl: result.imageUrl,
                category_name: result.category_name || '',
                color: result.color,
                stockSoldRatio: result.stockSoldRatio,
                knownColors: result.color ? [{ 
                    color_id: result.color.color_id,
                    quantity: 0,
                    priceGuideFetched: false
                }] : []
            } as LegoPart;

            setSelectedPart(part);
            setPartDetailsOpen(true);
        }
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        window.scrollTo(0, 0);
        setPage(value);
        if (hasSearched) {
            handleSearch();
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Advanced Search
            </Typography>

            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        label="Number Search"
                        placeholder="Search by part/set number"
                        value={numberSearch}
                        onChange={(e) => {
                            setNumberSearch(e.target.value);
                            setPage(1);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        multiple
                        options={ALL_TYPES}
                        value={selectedTypes}
                        onChange={(event, newValue) => {
                            setSelectedTypes(newValue);
                            setPage(1);
                        }}
                        getOptionLabel={(option) => TYPE_LABELS[option]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Types"
                                placeholder="Select types"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    label={TYPE_LABELS[option]}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ClickAwayListener onClickAway={handleCategoryClickAway}>
                        <Box sx={{ position: 'relative' }}>
                            <TextField
                                fullWidth
                                label="Categories"
                                placeholder="Type to search categories"
                                value={categoryInput}
                                onChange={handleCategoryInputChange}
                                onFocus={() => {
                                    setShowCategorySuggestions(true);
                                    if (categoryInput.trim()) {
                                        handleCategoryInputChange({ target: { value: categoryInput } } as React.ChangeEvent<HTMLInputElement>);
                                    }
                                }}
                                InputProps={{
                                    endAdornment: loadingCategorySuggestions && (
                                        <CircularProgress color="inherit" size={20} />
                                    ),
                                }}
                            />
                            {showCategorySuggestions && categorySuggestions.length > 0 && (
                                <Paper 
                                    sx={{ 
                                        position: 'absolute', 
                                        top: '100%', 
                                        left: 0, 
                                        right: 0, 
                                        zIndex: 1000,
                                        maxHeight: 200,
                                        overflow: 'auto'
                                    }}
                                >
                                    <List>
                                        {categorySuggestions.map((suggestion) => (
                                            <ListItem
                                                key={suggestion}
                                                onClick={() => handleCategorySelect(suggestion)}
                                                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'action.hover' } }}
                                            >
                                                {suggestion}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            )}
                        </Box>
                    </ClickAwayListener>
                    <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedCategories.map((category) => (
                            <Chip
                                key={category}
                                label={category}
                                onDelete={() => {
                                    setSelectedCategories(selectedCategories.filter(c => c !== category));
                                    setPage(1);
                                }}
                            />
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ClickAwayListener onClickAway={handleColorClickAway}>
                        <Box sx={{ position: 'relative' }}>
                            <TextField
                                fullWidth
                                label="Colors"
                                placeholder="Type to search colors"
                                value={colorInput}
                                onChange={handleColorInputChange}
                                onFocus={() => {
                                    setShowColorSuggestions(true);
                                    if (colorInput.trim()) {
                                        handleColorInputChange({ target: { value: colorInput } } as React.ChangeEvent<HTMLInputElement>);
                                    }
                                }}
                                InputProps={{
                                    endAdornment: loadingColorSuggestions && (
                                        <CircularProgress color="inherit" size={20} />
                                    ),
                                }}
                            />
                            {showColorSuggestions && colorSuggestions.length > 0 && (
                                <Paper 
                                    sx={{ 
                                        position: 'absolute', 
                                        top: '100%', 
                                        left: 0, 
                                        right: 0, 
                                        zIndex: 1000,
                                        maxHeight: 200,
                                        overflow: 'auto'
                                    }}
                                >
                                    <List>
                                        {colorSuggestions.map((color) => (
                                            <ListItem
                                                key={color.color_id}
                                                onClick={() => handleColorSelect(color)}
                                                sx={{ cursor: 'pointer', '&:hover': { bgcolor: 'action.hover' } }}
                                            >
                                                <ColorBox colorCode={color.color_code} />
                                                {color.color_name}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            )}
                        </Box>
                    </ClickAwayListener>
                    <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedColors.map((color) => (
                            <Chip
                                key={color.color_id}
                                icon={<ColorBox colorCode={color.color_code} />}
                                label={color.color_name}
                                onDelete={() => {
                                    setSelectedColors(selectedColors.filter(c => c.color_id !== color.color_id));
                                    setPage(1);
                                }}
                            />
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleSearch}
                                disabled={selectedTypes.length === 0}
                                sx={{ minWidth: 120 }}
                            >
                                Search
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={async () => {
                                    setLoading(true);
                                    try {
                                        const response = await apiService.searchParts({
                                            page: 1,
                                            limit: 1000000,
                                            types: selectedTypes,
                                            categories: selectedCategories,
                                            colors: selectedColors.map(c => c.color_id),
                                            separateColors,
                                            stockSoldRatio,
                                            numberSearch: numberSearch.trim(),
                                            soldQuantityFilter: {
                                                operator: soldQuantityOperator,
                                                value1: soldQuantityValue1,
                                                ...(soldQuantityOperator === 'BETWEEN' && { value2: soldQuantityValue2 })
                                            }
                                        });

                                        const allResults = response.data.data.parts;

                                        const escapeCSV = (field: string | number) => {
                                            const str = String(field);
                                            if (str.includes(',') || str.includes('"') || str.includes('\n')) {
                                                return `"${str.replace(/"/g, '""')}"`;
                                            }
                                            return str;
                                        };

                                        const csvContent = [
                                            ['Part Number', 'Name', 'Color', 'Stock Price', 'Sold Price', '6M Sales', 'Stock/Sold'].map(escapeCSV),
                                            ...allResults.map(result => [
                                                escapeCSV(result.no),
                                                escapeCSV(result.name),
                                                escapeCSV(result.color?.color_name || ''),
                                                escapeCSV(result.stockMinPrice ? `$${parseFloat(result.stockMinPrice).toFixed(3)}` : ''),
                                                escapeCSV(result.soldAvgPrice ? `$${parseFloat(result.soldAvgPrice).toFixed(3)}` : ''),
                                                escapeCSV(result.soldQuantity || ''),
                                                escapeCSV(result.stockSoldRatio ? result.stockSoldRatio.toFixed(2) : '')
                                            ])
                                        ].map(row => row.join(',')).join('\n');

                                        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                                        const link = document.createElement('a');
                                        const url = URL.createObjectURL(blob);
                                        link.setAttribute('href', url);
                                        link.setAttribute('download', 'search_results.csv');
                                        link.style.visibility = 'hidden';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    } catch (error) {
                                        console.error('Error exporting results:', error);
                                    } finally {
                                        setLoading(false);
                                    }
                                }}
                                disabled={results.length === 0 || loading}
                                startIcon={loading ? <CircularProgress size={20} /> : undefined}
                                sx={{ minWidth: 120 }}
                            >
                                {loading ? 'Exporting...' : 'Export CSV'}
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={separateColors}
                                        onChange={(e) => setSeparateColors(e.target.checked)}
                                    />
                                }
                                label="Show each color separately"
                            />
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel>Stock/Sold Ratio</InputLabel>
                                <Select
                                    value={stockSoldRatio}
                                    onChange={(e) => setStockSoldRatio(e.target.value as StockSoldRatio)}
                                    label="Stock/Sold Ratio"
                                >
                                    {Object.entries(RATIO_LABELS).map(([value, label]) => (
                                        <MenuItem key={value} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 150 }}>
                                <InputLabel>6 Month Sold Qty</InputLabel>
                                <Select
                                    value={soldQuantityOperator}
                                    onChange={(e) => setSoldQuantityOperator(e.target.value as SoldQuantityOperator)}
                                    label="6 Month Sold Qty"
                                >
                                    <MenuItem value="GT">Greater Than</MenuItem>
                                    <MenuItem value="LT">Less Than</MenuItem>
                                    <MenuItem value="BETWEEN">Between</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                type="number"
                                label={soldQuantityOperator === 'BETWEEN' ? 'Min Value' : 'Value'}
                                value={soldQuantityValue1}
                                onChange={(e) => setSoldQuantityValue1(parseInt(e.target.value) || 0)}
                                sx={{ width: 100 }}
                            />
                            {soldQuantityOperator === 'BETWEEN' && (
                                <TextField
                                    type="number"
                                    label="Max Value"
                                    value={soldQuantityValue2}
                                    onChange={(e) => setSoldQuantityValue2(parseInt(e.target.value) || 0)}
                                    sx={{ width: 100 }}
                                />
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {loading ? (
                <Box display="flex" justifyContent="center" p={3}>
                    <CircularProgress />
                </Box>
            ) : results.length > 0 ? (
                <>
                    <TableContainer component={Paper} variant="outlined">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width={100}>Image</TableCell>
                                    <TableCell width={150}>Number</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell width={150}>Color</TableCell>
                                    <TableCell width={100} align="right">Stock Price</TableCell>
                                    <TableCell width={100} align="right">Sold Price</TableCell>
                                    <TableCell width={100} align="right">6M Sales</TableCell>
                                    <TableCell width={100} align="right">Stock/Sold</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.map((result) => (
                                    <TableRow
                                        key={result.id}
                                        hover
                                        onClick={() => handleItemClick(result)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>
                                            <Box
                                                component="img"
                                                src={result.imageUrl}
                                                alt={result.name}
                                                sx={{
                                                    width: 60,
                                                    height: 60,
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{result.no}</TableCell>
                                        <TableCell>{result.name}</TableCell>
                                        <TableCell>
                                            {result.color && (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <ColorBox colorCode={result.color.color_code} />
                                                    {result.color.color_name}
                                                </Box>
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {result.stockMinPrice ? `$${parseFloat(result.stockMinPrice).toFixed(3)}` : '-'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {result.soldAvgPrice ? `$${parseFloat(result.soldAvgPrice).toFixed(3)}` : '-'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {result.soldQuantity || '-'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {result.stockSoldRatio ? result.stockSoldRatio.toFixed(2) : '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box display="flex" justifyContent="center" mt={4}>
                        <Pagination 
                            count={totalPages} 
                            page={page} 
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            ) : (
                <Typography color="text.secondary" align="center">
                    {!hasSearched ? (
                        "Select types and click Search to find items"
                    ) : separateColors ? (
                        "No items found with available colors. Try disabling 'Show each color separately' or selecting different filters."
                    ) : (
                        "No items found matching your search criteria"
                    )}
                </Typography>
            )}

            <PartDetails
                open={partDetailsOpen}
                onClose={() => {
                    setPartDetailsOpen(false);
                    setSelectedPart(null);
                }}
                part={selectedPart}
            />

            <LegoSetDetails
                open={setDetailsOpen}
                onClose={() => {
                    setSetDetailsOpen(false);
                    setSelectedSet(null);
                }}
                set={selectedSet}
            />
        </Container>
    );
};

export default Search;
