import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from './theme';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute } from './components/common/ProtectedRoute';
import { Navigation } from './components/common/Navigation';
import { Login } from './components/auth/Login';
import { Register } from './components/auth/Register';
import LegoSets from './components/lego/LegoSets';
import Profile from './components/user/Profile';
import UserManagement from './components/admin/UserManagement';
import Parts from './components/lego/Parts';
import Search from './components/lego/Search';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <AuthProvider>
                    <Navigation />
                    <Routes>
                        {/* Public Routes */}
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />

                        {/* Protected Routes */}
                        <Route element={<ProtectedRoute />}>
                            <Route path="/sets" element={<LegoSets />} />
                            <Route path="/parts" element={<Parts />} />
                            <Route path="/search" element={<Search />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/" element={<Navigate to="/sets" replace />} />
                        </Route>

                        {/* Admin Routes */}
                        <Route element={<ProtectedRoute requireAdmin />}>
                            <Route path="/admin/users" element={<UserManagement />} />
                        </Route>

                        {/* Catch all - redirect to sets */}
                        <Route path="*" element={<Navigate to="/sets" replace />} />
                    </Routes>
                </AuthProvider>
            </Router>
        </ThemeProvider>
    );
};

export default App;
