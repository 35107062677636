import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    TextField,
    InputAdornment,
    IconButton,
    Alert,
    CircularProgress,
    Pagination,
    CardActionArea,
    Chip,
    Autocomplete
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { apiService } from '../../services/api.service';
import { LegoPart } from '../../types/lego.types';
import PartDetails from './PartDetails';

const typeColors: { [key: string]: string } = {
    MINIFIG: '#FFA726', // Orange
    PART: '#66BB6A', // Green
    SET: '#42A5F5', // Blue
    BOOK: '#AB47BC', // Purple
    GEAR: '#EF5350', // Red
    CATALOG: '#EC407A', // Pink
    INSTRUCTION: '#7E57C2', // Deep Purple
    UNSORTED_LOT: '#78909C', // Blue Grey
    ORIGINAL_BOX: '#FFB74D', // Orange Light
};

const Parts: React.FC = () => {
    const [parts, setParts] = useState<LegoPart[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingSuggestions, setLoadingSuggestions] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [searchInput, setSearchInput] = useState('');
    const [categoryInput, setCategoryInput] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedPart, setSelectedPart] = useState<LegoPart | null>(null);
    const [detailsOpen, setDetailsOpen] = useState(false);

    // Search timer ref
    const searchTimerRef = React.useRef<NodeJS.Timeout>();
    const categorySearchTimerRef = React.useRef<NodeJS.Timeout>();

    const fetchParts = useCallback(async (searchTerm: string) => {
        try {
            setLoading(true);
            setError(null);

            const params: any = {
                page,
                limit: 12
            };

            if (searchTerm.trim()) params.search = searchTerm;
            if (selectedCategory) params.category_name = selectedCategory;

            const response = await apiService.searchParts(params);
            setParts(response.data.data.parts);
            setTotalPages(response.data.data.pagination.pages);
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to fetch LEGO parts');
            setParts([]); // Clear parts on error
        } finally {
            setLoading(false);
        }
    }, [page, selectedCategory]);

    // Effect to fetch parts when dependencies change
    useEffect(() => {
        fetchParts(searchInput);
    }, [fetchParts, searchInput, selectedCategory, page]);

    // Handle search input changes with debouncing
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setSearchInput(newValue);
        setPage(1);
    };

    // Handle category input changes
    const handleCategoryInputChange = async (_: any, newValue: string) => {
        setCategoryInput(newValue);
        
        // Clear existing timer
        if (categorySearchTimerRef.current) {
            clearTimeout(categorySearchTimerRef.current);
        }

        // Set new timer for fetching suggestions
        categorySearchTimerRef.current = setTimeout(async () => {
            if (newValue.trim()) {
                setLoadingSuggestions(true);
                try {
                    const response = await apiService.getCategorySuggestions(newValue);
                    setSuggestions(response.data.data);
                } catch (error) {
                    console.error('Error fetching category suggestions:', error);
                    setSuggestions([]);
                } finally {
                    setLoadingSuggestions(false);
                }
            } else {
                setSuggestions([]);
            }
        }, 300);
    };

    // Handle category selection
    const handleCategoryChange = (_: any, newValue: string | null) => {
        setSelectedCategory(newValue);
        if (newValue === null) {
            setCategoryInput('');
        }
        setPage(1);
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handlePartClick = (part: LegoPart) => {
        setSelectedPart(part);
        setDetailsOpen(true);
    };

    const getStockToSoldRatio = (part: LegoPart): string => {
        const stockGuide = part.stockPriceGuides?.[0];
        const soldGuide = part.soldPriceGuides?.[0];
        
        if (!stockGuide?.price_data || !soldGuide?.price_data) return '-';
        
        const stockQuantity = stockGuide.price_data.total_quantity;
        const soldQuantity = soldGuide.price_data.total_quantity;
        
        return soldQuantity > 0 ? (stockQuantity / soldQuantity).toFixed(2) : '-';
    };

    const getPartImageUrl = (part: LegoPart) => {
        if (part.type === 'MINIFIG') {
            return `https://img.bricklink.com/ItemImage/MN/0/${part.no}.png`;
        }
        return `https://img.bricklink.com/PL/${part.no}.jpg`;
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                LEGO Parts
            </Typography>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Search LEGO Parts"
                            value={searchInput}
                            onChange={handleSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            inputValue={categoryInput}
                            onInputChange={handleCategoryInputChange}
                            options={suggestions}
                            loading={loadingSuggestions}
                            filterOptions={(x) => x} // Disable built-in filtering
                            noOptionsText={categoryInput ? "No categories found" : "Type to search categories"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Filter by Category"
                                    placeholder="Type to search categories"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingSuggestions ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>

            {loading ? (
                <Box display="flex" justifyContent="center" p={3}>
                    <CircularProgress />
                </Box>
            ) : parts.length === 0 ? (
                <Alert severity="info">No LEGO parts found</Alert>
            ) : (
                <>
                    <Grid container spacing={3}>
                        {parts.map((part) => {
                            const stockGuide = part.stockPriceGuides?.[0];
                            const soldGuide = part.soldPriceGuides?.[0];
                            return (
                                <Grid item key={part._id} xs={12} sm={6} md={4}>
                                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <CardActionArea onClick={() => handlePartClick(part)}>
                                            <CardMedia
                                                component="img"
                                                height="200"
                                                image={getPartImageUrl(part)}
                                                alt={part.name}
                                                sx={{ objectFit: 'contain', p: 2 }}
                                            />
                                            <CardContent sx={{ flexGrow: 1 }}>
                                                <Typography gutterBottom variant="h6" component="h2">
                                                    {part.name}
                                                </Typography>
                                                <Box sx={{ mb: 1 }}>
                                                    <Box display="flex" alignItems="center" gap={1} mb={0.5}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Part Number: {part.no}
                                                        </Typography>
                                                        <Chip
                                                            label={part.type}
                                                            size="small"
                                                            sx={{
                                                                bgcolor: typeColors[part.type] || 'grey.500',
                                                                color: 'white',
                                                                fontWeight: 'bold'
                                                            }}
                                                        />
                                                    </Box>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Category: {part.category_name || 'Unknown'}
                                                    </Typography>
                                                </Box>
                                                {stockGuide && (
                                                    <>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Stock Price: ${parseFloat(stockGuide.price_data.min_price).toFixed(2)}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Sold Price: ${parseFloat(soldGuide?.price_data.qty_avg_price || '0').toFixed(2)}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Stock/Sold Ratio: {getStockToSoldRatio(part)}
                                                        </Typography>
                                                    </>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            )}

            <PartDetails
                open={detailsOpen}
                onClose={() => {
                    setDetailsOpen(false);
                    setSelectedPart(null);
                }}
                part={selectedPart}
            />
        </Container>
    );
};

export default Parts;
